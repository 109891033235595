<template>
  <v-main class="pb-0">
    <v-container fluid class="pa-0">
      <v-row
        class="fill-height parallax"
        align="center"
        justify="center"
        id="Inicio"
      >
        <v-col cols="12" class="py-0">
          <v-row class="image" align="center" justify="center">
            <v-col cols="12" md="6" class="text-center">
              <h1 class="white--text mb-6 text-uppercase inicio">
                Convierte Tu Idea En Una Página/Aplicación Web Profesional.
              </h1>
              <v-btn
                outlined
                color="white"
                class="text-capitalize"
                @click.stop="$vuetify.goTo('#SobreMi', { duration: 1000 })"
              >
                Muéstrame Como
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <SobreMi />
      <Portafolio />
      <TimeLine />
      <!-- <Contact /> -->
    </v-container>
    <v-btn
      fab
      bottom
      right
      dark
      large
      color="#00E676"
      fixed
      target="_blank"
      class="whatsApp"
      href="https://api.whatsapp.com/send?phone=524441176494&text=Hola,%20me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n."
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </v-main>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {
    // Contact: () => import("@/views/Home/Contact.vue"),
    SobreMi: () => import("@/views/Home/SobreMi.vue"),
    Portafolio: () => import("@/views/Home/Portafolio.vue"),
    TimeLine: () => import("@/views/Home/TimeLine.vue")
  }
};
</script>
<style scoped>
.bg-color {
  background-color: rgba(8, 103, 136, 0.4);
}
.presentacion {
  height: 35vh;
  display: flex;
  align-items: center !important;
  justify-items: center !important;
  margin: auto;
}
.image {
  z-index: 1;
  background-color: black;
  background-image: url("../assets/images/backgroundBloque01-Negro.png"),
    linear-gradient(to right, white, black);
  background-repeat: no-repeat; /* Do not repeat the image */
  height: 90vh;
  background-size: 250vh;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-name: moveLeftAndRight;
  position: relative;
}
@keyframes moveLeftAndRight {
  0% {
    background-position: right 0% top 0%;
  }
  25% {
    background-position: right 50% top 0%;
  }
  50% {
    background-position: right 100% top 0%;
  }
  75% {
    background-position: right 50% top 0%;
  }
  100% {
    background-position: right 0% top 0%;
  }
}
.whatsApp {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: slidein;
}
@keyframes slidein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
